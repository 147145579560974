<template>
  <modal name="modal-info-srt326" class="modal-inner modal-info-srt326" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="200" height="auto" :scrollable="true">
    <span class="absolute top-1 right-2 cursor-pointer" @click="hide('modal-info-srt326')"><BaseIcon icon="times-circle" class=""/></span>
    <div class="flex flex-col px-4 py-4 text-sm" v-if="alarmInfo">
      <div>{{ $t("alarm_gps_info.name_of_alarm") }} {{ alarmInfo.alarm_name }}</div>
      <div>{{ $t("alarm_gps_info.gsm_signal") }} {{ alarmInfo.gsm_signal }}</div>
      <div>{{ $t("alarm_gps_info.battery_percentage") }} {{ alarmInfo.battery }}</div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 250;

export default {
  props: {
    alarmInfo: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      user: this.$store.state.user,
    };
  },
  methods: {
    hide(id) {
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
